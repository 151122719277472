import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getNotficationsAPi } from "../API/Userapis";
import { setheadermenuData } from "../reducers/HeaderMenuReducer";
import { useNavigate } from "react-router-dom";
import NewsNotification from "../components/NotificationCards/NewsNotification";
import ForumNotfication from "../components/NotificationCards/ForumNotfication";
import FinancialNotfication from "../components/NotificationCards/FinancialNotfication";
import { GAEvenet } from "../API/GoogleAnalytics";

const Notificationpage = () => {
  const navigate = useNavigate();
  const [Notifications, setNotifications] = useState([]);
  const [offset, setOffset] = useState(0);
  const [loader, setLoader] = useState(false);
  const [hasMoreNotification, setHasMoreNotification] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setheadermenuData({
        currentpath: "/notifications",
        headerfootershow: true,
      })
    );
    GAEvenet();

    window.addEventListener("scroll", handleScroll);
    // return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    // console.log("getNotficationsAPi", offset);
    setLoader(true);
    setTimeout(() => {
      getNotficationsAPi(10, offset).then((meta) => {
        if (meta.length > 0) {
          // console.log("Meta", meta);
          setLoader(false);
          setNotifications([...Notifications, ...meta]);
          window.addEventListener("scroll", handleScroll);
          // setNotifications(meta);
        } else {
          setLoader(false);
          setHasMoreNotification(false);
          window.removeEventListener("scroll", handleScroll);
        }
      });
    }, 1000);
  }, [offset]);

  const handleScroll = () => {
    if (hasMoreNotification) {
      if (
        window.innerHeight +
          parseInt(document.documentElement.scrollTop) +
          300 >
        document.documentElement.offsetHeight
      ) {
        // console.log("Handle Scroll");
        // setHasMoreNotification(false);
        setOffset(Number(offset) + 10);
        window.removeEventListener("scroll", handleScroll);
      }
    }
    // console.log(window.innerHeight);
    // console.log(
    //   window.innerHeight + parseInt(document.documentElement.scrollTop) + 300
    // );
    // console.log(document.documentElement.offsetHeight);
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div style={{ minHeight: "90vh" }}>
        <div className="container-fluid nopaddingcontainer mb-2 d-flex justify-content-center">
          <div className="container card section_divider">
            <div className="row section_divider">
              <div className="col-md-12">
                <Button
                  style={{
                    borderTopLeftRadius: "5px",
                    borderBottomLeftRadius: "5px",
                    transform: "translateX(-10px)",
                  }}
                  onClick={handleBack}
                >
                  {" "}
                  <span className="back_btn_txt2">
                    <img
                      alt="Back"
                      style={{ transform: "rotateZ(90deg)" }}
                      srcSet="/assets/icons/Dropdown.svg"
                    />{" "}
                    Back
                  </span>
                </Button>
              </div>
            </div>
            <div className="row news_label_with_search_row pt-1">
              <div className="col-md-6">
                <label className="labelasheading">Notifications</label>
                <br />
              </div>
            </div>
            <div className="row">
              {Notifications == "" ? (
                <div
                  className="col-md-6 "
                  id="showMe"
                  style={{ marginLeft: "45%", marginTop: "15%" }}
                >
                  <span
                    className="spinner spinner-border spinner-border-md my-auto py-auto"
                    style={{
                      position: "absolute",
                      color: "#353030",
                      margin: "auto",
                    }}
                  ></span>
                </div>
              ) : (
                <div className="col-md-6 offset-md-3">
                  {Notifications.map((notification, index) => {
                    //console.log(notification.metadata);

                    if (
                      notification.metadata != "" &&
                      notification.metadata != null
                    ) {
                      let notify_type = notification.metadata.type;
                      if (notify_type == "News") {
                        return (
                          <NewsNotification
                            key={index}
                            notification={notification}
                          />
                        );
                      } else if (notify_type == "Forum") {
                        return (
                          <ForumNotfication
                            key={index}
                            notification={notification}
                          />
                        );
                      }
                      if (notify_type == "Financial Report") {
                        return (
                          <FinancialNotfication
                            key={index}
                            notification={notification}
                          />
                        );
                      }
                    }
                  })}
                  {loader ? (
                    <div
                      style={{ minHeight: "100px" }}
                      className="row custom-feed-loader-div justify-content-center"
                    >
                      <div className="custom-feed-loader"></div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notificationpage;
