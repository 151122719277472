import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import { base_url, timeSince, userEventAPI } from "../API/Userapis";
import axios from "axios";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  RedditShareButton,
  RedditIcon,
  VKShareButton,
  VKIcon,
  EmailShareButton,
  EmailIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";
import { Helmet } from "react-helmet";

export default function CustomFeed() {
  const navigate = useNavigate();
  const imgPath = "/assets/icons/";
  const PlaceHolderImg = imgPath + "placeholder.png";
  const LinkIcon = imgPath + "link-icon.svg";

  let url = window.location.href;

  let params = useParams();
  let feeduid = params.uuid;
  const urlArr = feeduid.split("-");
  let rssFeedId = urlArr.slice(-1) != "undefined" ? urlArr.slice(-1) : "";
  rssFeedId = rssFeedId[0];

  const [title, setTitle] = useState("");
  const [date, setDate] = useState("");
  const [mainImg, setMainImg] = useState("");
  const [originator, setOriginator] = useState("");
  const [imageTitle, setImageTitle] = useState("");
  const [imageDescription, setImageDescription] = useState("");
  const [description, setDescription] = useState("");
  const [showViewed, setShowViewed] = useState([]);
  const [showRecent, setShowRecent] = useState([]);
  const [getMyIp, setGetMyIp] = useState();
  const [loading, setLoading] = useState(false);
  const [fileAttach, setFileAttach] = useState([]);
  const [baseUrl, setBaseUrl] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setLoading(true);
    }, 2200);
  });

  const handleBack = () => {
    navigate(-1);
  };

  const getData = async () => {
    const res = await axios.get("https://api.ipify.org/");
    setGetMyIp(res.data);
  };

  function viewCount() {
    if (getMyIp != null && getMyIp != undefined && getMyIp != "") {
      var bodyFormData = new FormData();
      bodyFormData.append("rss_id", rssFeedId);
      bodyFormData.append("ip_address", getMyIp);

      axios({
        method: "post",
        url: base_url + `api/webservice/update_view_count`,
        headers: {
          "Content-Type": "multipart/form-data",
          "X-API-KEY": "ashom@123",
        },
        data: bodyFormData,
      })
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function customFeed() {
    axios({
      method: "get",
      url: base_url + `api/webservice/custom_rss?custom_feed_id=${rssFeedId}`,
      headers: {
        "Content-Type": "multipart/form-data",
        "X-API-KEY": "ashom@123",
      },
    })
      .then(function (response) {
        console.log(response);
        setMainImg(response.data.image_url);
        setTitle(response.data.title);
        setDate(response.data.date);
        setOriginator(response.data.originator_name);
        setImageTitle(response.data.main_picture_title);
        setImageDescription(response.data.main_picture_description);
        setDescription(response.data.description);
        setFileAttach(response.data.files);
        setBaseUrl(response.data.base_url);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function mostViewed() {
    var bodyFormData = new FormData();
    bodyFormData.append("rssfeed_id", rssFeedId);
    axios({
      method: "post",
      url: base_url + "api/webservice/most_visible_news",
      headers: {
        "Content-Type": "multipart/form-data",
        "X-API-KEY": "ashom@123",
      },
      data: bodyFormData,
    })
      .then(function (response) {
        // console.log(response.data.data);
        const resArr = response.data.data;
        setShowViewed([...resArr]);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function mostRecent() {
    var bodyFormData = new FormData();
    bodyFormData.append("rssfeed_id", rssFeedId);
    axios({
      method: "post",
      url: base_url + "api/webservice/most_recent_news",
      headers: {
        "Content-Type": "multipart/form-data",
        "X-API-KEY": "ashom@123",
      },
      data: bodyFormData,
    })
      .then(function (response) {
        // console.log(response.data.data);
        const resArr = response.data.data;
        setShowRecent([...resArr]);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    getData();
    viewCount();
  }, [getMyIp]);

  useEffect(() => {
    customFeed();
    mostViewed();
    mostRecent();
  }, []);

  const MostRead = (showRightData) => {
    return showRightData.map((mydata, index) => {
      let linkUrl =
        mydata.type == "custom"
          ? base_url + "news/" + mydata.uuid
          : mydata.link;
      let titleItem =
        mydata.type == "custom" ? mydata.originator_name : mydata.source;
      return (
        <div
          key={index}
          className="mid-section-right-article-links d-flex mb-2 pb-2 mt-3"
        >
          <div className="mid-section-right-article-links-img-wrapper">
            <span className="mid-section-right-article-links-img">
              <img
                src={mydata.image_url != "" ? mydata.image_url : PlaceHolderImg}
              />
            </span>
          </div>
          <div className="mid-section-right-article-links-wrapper">
            <a className="mid-section-right-article-links-title" href={linkUrl}>
              {mydata.title}
            </a>
            <div className="mid-section-links-name-date-div d-flex mt-1">
              <span className="mid-section-links-name">{titleItem}</span>
              <span className="mid-section-links-date">
                {timeSince(new Date(mydata.created_date))}
              </span>
            </div>
          </div>
        </div>
      );
    });
  };

  const MostRecent = (showRecentData) => {
    return showRecentData.map((mydata, index) => {
      let linkUrl =
        mydata.type == "custom"
          ? base_url + "news/" + mydata.uuid
          : mydata.link;
      let titleItem =
        mydata.type == "custom" ? mydata.originator_name : mydata.source;
      return (
        <div
          key={index}
          className="mid-section-right-article-links d-flex mb-2 pb-2 mt-3"
        >
          <div className="mid-section-right-article-links-img-wrapper">
            <span className="mid-section-right-article-links-img">
              <img
                src={mydata.image_url != "" ? mydata.image_url : PlaceHolderImg}
              />
            </span>
          </div>
          <div className="mid-section-right-article-links-wrapper">
            <a className="mid-section-right-article-links-title" href={linkUrl}>
              {mydata.title}
            </a>
            <div className="mid-section-links-name-date-div d-flex mt-1">
              <span className="mid-section-links-name">{titleItem}</span>
              <span className="mid-section-links-date">
                {timeSince(new Date(mydata.created_date))}
              </span>
            </div>
          </div>
        </div>
      );
    });
	};
	
	var canonicalUrl = "https://ashom.app/news/" + rssFeedId;

  const share_url_txt = `Hi, I am using Ashom.app. Feel free to download\niOS App:\nhttps://rb.gy/wifqrz \n\nAndroid App:\nhttps://rb.gy/xwfuw \n\nNews:\n${url}`;
  return (
    <>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <div className="container mid-container">
        <div className="card mid-section-wrapper">
          {!loading ? (
            <div className="row custom-feed-loader-div justify-content-center">
              <div class="custom-feed-loader"></div>
            </div>
          ) : (
            ""
          )}

          {loading ? (
            <div className="row">
              <div className="col-lg-8 col-xl-8 col-md-12 col-sm-12">
                <div className="mid-section-back-btn-div">
                  <Button onClick={handleBack} className="mid-section-back-btn">
                    <img srcset="/assets/icons/Dropdown.svg" />
                    BACK
                  </Button>
                </div>
                <div className="mid-section-heading pt-3">
                  <h2>{title}</h2>
                </div>
                <div className="mid-section-date-name-div">
                  <span className="mid-section-date">{date}</span>
                  <span className="mid-section-user-name">{originator}</span>
                </div>

                {mainImg != null && mainImg != "" && mainImg != undefined ? (
                  <div className="mid-section-img-div">
                    <img
                      className="mid-section-img"
                      src={mainImg ? mainImg : PlaceHolderImg}
                    />
                    <div className="mid-section-img-detail">
                      <p className="mid-section-img-title">{imageTitle}</p>
                      <p className="mid-section-img-description">
                        {imageDescription}
                      </p>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="mid-section-description pb-4">
                  {/* {description} */}
                  <div
                    className="mid-section-description-div"
                    dangerouslySetInnerHTML={{ __html: description }}
                  ></div>
                </div>
                <div className="mid-section-description-attachment-wrapper">
                  {fileAttach != undefined
                    ? fileAttach.map((singlefile, index) => {
                        return (
                          <span
                            key={index + "_files"}
                            className="filesDownload"
                          >
                            <a href={`${baseUrl}${singlefile.file}`} download>
                              <img src={LinkIcon} />
                              {singlefile.file.replace("uploads/", "")}
                            </a>
                          </span>
                        );
                      })
                    : ""}
                </div>
                <div className="mid-section-social-div align-items-center">
                  <div className="row">
                    <div className="col-md-12">Share on</div>
                  </div>
                  <div className="row">
                    <div className="col-lg-5 col-md-8 col-sm-12">
                      <Grid
                        container
                        spacing={1}
                        className="justify-content-around"
                      >
                        <Grid item>
                          <FacebookShareButton
                            onClick={() => userEventAPI("news_share")}
                            url={url}
                            quote={share_url_txt}
                            hashtag="#ashom.app"
                          >
                            <FacebookIcon logoFillColor="white" size={32} />
                          </FacebookShareButton>
                        </Grid>
                        <Grid item>
                          <WhatsappShareButton
                            onClick={() => userEventAPI("news_share")}
                            url={share_url_txt}
                          >
                            <WhatsappIcon logoFillColor="white" size={32} />
                          </WhatsappShareButton>
                        </Grid>
                        <Grid item>
                          <EmailShareButton
                            onClick={(_, link) => {
                              window.open(link);
                              userEventAPI("news_share");
                            }}
                            url={url}
                            body={share_url_txt}
                            subject="From Ashom News"
                          >
                            <EmailIcon logoFillColor="white" size={32} />
                          </EmailShareButton>
                        </Grid>

                        <Grid item>
                          <RedditShareButton
                            onClick={() => userEventAPI("news_share")}
                            url={share_url_txt}
                          >
                            <RedditIcon logoFillColor="white" size={32} />
                          </RedditShareButton>
                        </Grid>

                        <Grid item>
                          <VKShareButton
                            onClick={() => userEventAPI("news_share")}
                            url={share_url_txt}
                          >
                            <VKIcon logoFillColor="white" size={32} />
                          </VKShareButton>
                        </Grid>

                        <Grid item>
                          <LinkedinShareButton
                            onClick={() => userEventAPI("news_share")}
                            url={url}
                            summary={share_url_txt}
                          >
                            <LinkedinIcon logoFillColor="white" size={32} />
                          </LinkedinShareButton>
                        </Grid>

                        <Grid item>
                          <TwitterShareButton
                            onClick={() => userEventAPI("news_share")}
                            url={share_url_txt}
                          >
                            <TwitterIcon logoFillColor="white" size={32} />
                          </TwitterShareButton>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                  {/* <div className='d-flex'>
                                <div className='mid-section-follow-us-twitter'>
                                    <a target='_blank' href={`https://twitter.com/intent/tweet?ref_src=twsrc%5Etfw&text=${title}&tw_p=tweetbutton&url=${url}`} className='twitter-share-icon-div'>
                                        <img srcSet="/assets/icons/twitter.png" />
                                    </a>
                                </div>
                                <div className='mid-section-follow-us-linkedin'>
                                    <a target='_blank' href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`} className='linkedin-share-icon-div mx-2'>
                                        <img srcSet="/assets/icons/linkedin.png" />
                                    </a>
                                </div>
                                <div className='mid-section-follow-us-fb'>
                                    <a target='_blank' href={`https://www.facebook.com/sharer.php?href=${url}&title=${title}`} className='facebook-share-icon-div'>
                                        <img srcSet="/assets/icons/facebook.png" />
                                    </a>
                                </div>
                            </div> */}
                </div>
              </div>
              <div className="col-lg-4 col-xl-4 col-md-12 col-sm-12">
                <div
                  className={
                    showViewed != "" ? "mid-section-right-wrapper" : "d-none"
                  }
                >
                  <span className="mid-section-right-most-read">Most Read</span>
                  {showViewed != "" && showViewed != undefined ? (
                    <div>{MostRead(showViewed)}</div>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className={
                    showRecent != "" ? "mid-section-right-wrapper" : "d-none"
                  }
                >
                  <span className="mid-section-right-most-read">
                    Most Recent
                  </span>
                  {showRecent != "" && showRecent != undefined ? (
                    <div>{MostRecent(showRecent)}</div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}
