import React, { memo, useState } from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, CircularProgress, TableCell, TableContainer, TableSortLabel } from "@mui/material";
import { visuallyHidden } from '@mui/utils';

function EnhancedTableHead(props) {
  const { order, orderBy, numSelected, onRequestSort, headCells } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            sx={{
              textAlign: "{headCell?.align ?? 'center'}",
              backgroundColor: "#EFF0F4",
              color: "#000000 !important",
              fontWeight: 600,
              fontSize: "12px",
              px: "2px",
              py: 2,
            }}
            key={headCell.id}
            width={headCell?.width ?? undefined}
            align={headCell?.align ?? "center"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              sx={{
                // textAlign: "{headCell?.align ?? 'center'}",
                backgroundColor: "#EFF0F4",
                color: "#000000 !important",
                "& .MuiTableSortLabel-icon": {
                  color: "black !important",
                },
              }}
              // hideSortIcon={true}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const TableComponent = (props) => {
  let { rows, columns, renderRow, defaultOrderBy, isLoading } = props;
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(defaultOrderBy ? defaultOrderBy : '');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function descendingComparator(a, b, orderBy) {
    if (orderBy == "Volume" || orderBy == "percentage_change" || orderBy == "price_difference") {
      if (Number(b[orderBy]) < Number(a[orderBy])) {
        return -1;
      }
      if (Number(b[orderBy]) > Number(a[orderBy])) {
        return 1;
      }
      return 0;
    } else {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    }
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  return (
    <TableContainer>
      <Table
        aria-labelledby="tableTitle"
        size={"medium"}
        stickyHeader={true}
      >
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          headCells={columns}
        />
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell
                align="center"
                sx={{
                  height: "400px",
                }}
                colSpan={columns?.length}
              >
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : (
            <>
              {rows.length > 0 ? (
                <>
                  {stableSort(rows, getComparator(order, orderBy)).map(
                    renderRow
                  )}
                </>
              ) : (
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      height: "400px",
                    }}
                    colSpan={columns?.length}
                  >
                    No Data Found
                  </TableCell>
                </TableRow>
              )}
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default memo(TableComponent)
