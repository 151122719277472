import { Avatar, IconButton } from '@mui/material';
import React from 'react';
import MoreIcon from '@mui/icons-material/MoreVert';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { zonedTimeToUtc } from 'date-fns-tz';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import {deleteCommentApi, getForumComments, timeSince} from '../API/Userapis';
import { NavLink, Route, Router, Routes, useNavigate } from 'react-router-dom';

const Chatlist = (props) => {
    const {profile_pic, comment, created, date, id, in_reply, isMine, name, replied_to, replies} = props.chatdata;
    const {ForumData, setForumComments, setisEditing, setEditingText, setEditingCommentId, setReplayCommentId, setReplayText, handleRefreshReplies, setisReplaying} = props;
    var created_at = timeSince(new Date(created).setTime(new Date(created).getTime() - ((new Date().getTimezoneOffset() / 60) * 60 * 60 * 1000)));
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

  const handleToggle = () => {
    console.log(replies);
    setOpen((prevOpen) => !prevOpen);

  };

  
  const handleClose = (event) => {
   
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
    
      return;
    }
   
      
    setOpen(true);
  
  };

  const handleEditBtn =(e) =>{
    console.log(e);
    setisEditing(true);
    setEditingText(comment);
    setEditingCommentId(id);
    
    handleClose(e);
   
    // getForumComments(ForumData.id).then(meta => {
    //     ForumData.total_comments = meta.length;
    //     setForumComments(meta)
    // })
    }


  const deleteComment = (e) =>{
   
    deleteCommentApi(id).then(meta =>{
   
     
      getForumComments(ForumData.id).then(meta => {
        ForumData.total_comments = meta.length;
        console.log(meta);
        setForumComments(meta)
        
    })
    
    if(in_reply == true){
      document.getElementById(id).style.display= "none";
      document.getElementById("Hide").style.display= "none";
     }
     else if(replied_to == 0){
      console.log('firest_page');
     }
     else{
      document.getElementById(id).style.display= "none";
     }
    handleClose(e);
    
    })
   
  }
  
  function handleListKeyDown(event) {
    
    console.log(event);
    if (event.key === 'Tab') {
      event.preventDefault();
     
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);

  React.useEffect(() => {
   
    if (prevOpen.current === true && open === false) {
     
      anchorRef.current.focus();
      
    }
   
    prevOpen.current = open;

  }
  , [open]);

  const handlereplay = () =>{
   
    setisReplaying(true);
    setReplayCommentId(id);
    setReplayText("Reply: "+name+"- ");
   
  }
 
    return (
        <>
        <div id={id} >
           <div className="container chatlist_container" >
          
               <div className="row" id={id}>
               
                   <div className="col-2">
                   <Avatar
                      alt="A"
                      src={profile_pic}
                       sx={{ width: 38, height: 38 }}
                       style={{"float":"right"}}
                       
                    />
                   </div>
                   <div className="col-10">
      <div className="row" style={{ "height": "fit-content","background-color": "#e9ecef", "padding":"0.6rem","border-radius":"13px"
    }}>
      <div className="col-10">
      <div className="row">
                           <div className="col-12 chat_list_username" style={{"lineHeight":"0.5"}}>{name}</div>
                       </div>
                       <div className="row ">
                            <span className='col-12 comment_timing_txt' style={{"margin-top":"5px"}}>{created_at}</span>
                        </div>
                       <div className="row">
                        
                           <div className="col-12 chat_list_message">{comment}</div>
                       </div>
                      
      </div>
       <div className="col-2">
                       <div className="row">
                      {(isMine==1)?<MenuList
                    className='comment_menu_box '
                    style={{"margin-left": "auto","padding-top": "0px"}}
                    id="composition-menu"
                   
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                    
                  >
                   <li id="hidebtn" className='cursor' style={{"margin-left": "auto","padding-top": "0px"}}> <img id="myform" value={id} onClick={deleteComment} srcSet="/assets/icons/delete.svg" /> <img onClick={handleEditBtn} srcSet="/assets/icons/editing.svg" />  </li>
                  </MenuList>:""}
                       </div>
                       
                   </div>
                </div>
                     
                   </div>
                  
               </div>
           </div>
           <div className='row'>
                  <button className='col-6 commentreplaybtnn' onClick={handlereplay}><span className=''>Reply</span></button>
                     </div>   
                  </div>
        </>
    );
}

export default Chatlist;
