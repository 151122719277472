import { Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import { Chip, Stack } from "@mui/material";
import {
  getCompaniesByCountry,
  getCountries,
  userEventAPI,
} from "../API/Userapis";
import { getFlag, getSearch, setSearch } from "../API/LocalStore";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setheadermenuData } from "../reducers/HeaderMenuReducer";
import ScrollToTop from "react-scroll-to-top";
import CompanyListCard from "../components/CompanyListCard";
import { format } from "date-fns";
import { setcompanybycountryval } from "../reducers/CompanyByContry";
import { GAEvenet } from "../API/GoogleAnalytics";
import axios from "axios";

const Newspage = () => {
  const dispatch = useDispatch();
  const [currentNewsPosition, setcurrentNewsPosition] = useState(0);
  const [News, setNews] = useState([]);
  const [selectedCountry, setselectedCountry] = useState("");
  const [Countries, setCountries] = useState([]);
  const [hasmorenews, sethasmorenews] = useState(true);
  const [SearchText, setSearchText] = useState("");
  const [isSearching, setisSearching] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [TotalCompanies, setTotalCompanies] = useState(0);
  const [selectdexchnage, setselectdexchnage] = useState("");
  var parseCountry = useSelector(
    (state) => state.companiesbycountryreducer.value
  );
  const [selectedChildCountry, setselectedChildCountry] = useState("");
  const [search, setSearch] = useState(false);

  // const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  function changeCountry(country) {
    console.log("country", country);
    setcurrentNewsPosition(0);
    setselectedChildCountry("");
    setselectedCountry(country);
    setSearch("company_country", country);
    if (country == "Bahrain") {
      setSearch("selectedChildCountry", "BAHRAIN BOURSE");
      setselectedChildCountry("BAHRAIN BOURSE");
    } else if (country == "Qatar") {
      setSearch("selectedChildCountry", "QATAR STOCK EXCHANGE");
      setselectedChildCountry("QATAR STOCK EXCHANGE");
    } else {
      getCompaniesByCountry(country, 0, 0, "").then((meta) => {
        //Record user event
        if (country === "DFM" || country === "ADX")
          userEventAPI(`view_uae_company`);
        else userEventAPI(`view_${country.toLowerCase()}_company`);

        setTotalCompanies(meta.metadata.total_companies);
        setisSearching(false);
        setIsFetching(false);
        if (meta.data.length === 0) {
          sethasmorenews(false);
          if (meta.metadata.total_page === 0) setNews([]);
          return false;
        } else {
          sethasmorenews(true);
        }
        setNews(meta.data);
        setcurrentNewsPosition(1);
        // setSearch("selectedCountry", country);
        // setSearch("selectedChildCountry", "");
        // setselectedChildCountry("");
        // setselectedCountry(country);
      });
      setSearch("selectedChildCountry", "");
    }
    setSearchText("");
    setSearch("company", "");

    if (selectedCountry == "KSA") {
      document.getElementById("tasi").classList.remove("ksa-markets");
      document.getElementById("nomu").classList.remove("ksa-markets");
    } else if (selectedCountry == "UAE") {
      document.getElementById("dfm").classList.remove("uae-markets");
      document.getElementById("adx").classList.remove("uae-markets");
    } else if (selectedCountry == "Kuwait") {
      document.getElementById("main-mkt").classList.remove("kuwait-markets");
      document.getElementById("premier-mkt").classList.remove("kuwait-markets");
      // } else if (selectedCountry == "Bahrain") {
      //   document.getElementById("bahrain").classList.remove("bahrain-markets");
      // } else if (selectedCountry == "Qatar") {
      //   document.getElementById("qatar").classList.remove("qatar-markets");
    } else if (selectedCountry == "Oman") {
      document
        .getElementById("under-monitoring")
        .classList.remove("oman-markets");
      document.getElementById("regular-mkt").classList.remove("oman-markets");
      document.getElementById("parallel-mkt").classList.remove("oman-markets");
    }
  }

  async function fetchNews(searchext = 0, rsest = false) {
    setisSearching(true);
    // setIsFetching(true);
    getCompaniesByCountry(
      selectedCountry,
      rsest ? 0 : currentNewsPosition,
      SearchText,
      selectedChildCountry != "" ? selectedChildCountry : ""
    ).then((meta) => {
      setTotalCompanies(meta.metadata.total_companies);
      if (meta.data.length === 0) {
        sethasmorenews(false);
        if (meta.metadata.total_companies === 0) setNews([]);
        return false;
      } else {
        console.log(searchext);
        sethasmorenews(true);
        if (searchext !== 0) {
          setNews(meta.data);
        } else {
          setNews([...News, ...meta.data]);
        }
      }
      setisSearching(false);
      setIsFetching(false);
      setcurrentNewsPosition(currentNewsPosition + 1);
    });
  }

  const handleSearch = (Searchvalue) => {
    setNews([]);
    setSearchText(Searchvalue);
    setSearch("company", Searchvalue);
    setcurrentNewsPosition(0);
    setSearch(true);
  };

  useEffect(() => {
    if (search) {
      setSearch(false);
      const searchText = setTimeout(() => fetchNews(SearchText, 0), 1000);
      return () => clearTimeout(searchText);
      // fetchNews(SearchText, 0);
    }
  }, [SearchText]);

  // const getRowData = (dataRows, ex_type) => {
  //   const type = ex_type.trim();
  //   if (dataRows.length > 0) {
  //     if (type == "KSA") {
  //       return dataRows;
  //     } else if (type == "NOMU") {
  //       return dataRows.filter((row) => row.exchanges == "NOMU");
  //     } else if (type == "TASI") {
  //       return dataRows.filter((row) => row.exchanges == "TASI");
  //     } else if (type == "UAE") {
  //       return dataRows;
  //     } else if (type == "DFM") {
  //       return dataRows.filter((row) => row.exchanges == "DFM");
  //     } else if (type == "ADX") {
  //       return dataRows.filter((row) => row.exchanges == "ADX");
  //     } else if (type == "Oman") {
  //       return dataRows;
  //     } else if (type == "UNDER_MONITORING MARKET") {
  //       return dataRows.filter(
  //         (row) => row.exchanges == "UNDER_MONITORING MARKET"
  //       );
  //     } else if (type == "REGULAR MARKET") {
  //       return dataRows.filter((row) => row.exchanges == "REGULAR MARKET");
  //     } else if (type == "PARALLEL MARKET") {
  //       return dataRows.filter((row) => row.exchanges == "PARALLEL MARKET");
  //     } else if (type == "Kuwait") {
  //       return dataRows;
  //     } else if (type == "MAIN MARKET") {
  //       return dataRows.filter((row) => row.exchanges == "MAIN MARKET");
  //     } else if (type == "PREMIER MARKET") {
  //       return dataRows.filter((row) => row.exchanges == "PREMIER MARKET");
  //     } else if (type == "Bahrain") {
  //       return dataRows;
  //     } else if (type == "BAHRAIN BOURSE") {
  //       return dataRows.filter((row) => row.exchanges == "BAHRAIN BOURSE");
  //     } else if (type == "Qatar") {
  //       return dataRows;
  //     } else if (type == "QATAR STOCK EXCHANGE") {
  //       return dataRows.filter(
  //         (row) => row.exchanges == "QATAR STOCK EXCHANGE"
  //       );
  //     }
  //   }
  // };

  const startOvercompanies = (selectedCountrdy) => {
    let searchtext = "";
    if (getSearch("company")) searchtext = getSearch("company");
    setSearchText(searchtext);
    getCompaniesByCountry(
      selectedCountrdy,
      0,
      searchtext,
      selectedChildCountry != "" ? selectedChildCountry : ""
    ).then((meta) => {
      setNews(meta.data);
      setTotalCompanies(meta.metadata.total_companies);
      setcurrentNewsPosition(currentNewsPosition + 1);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    GAEvenet();
    userEventAPI(`company_page_view`);
    dispatch(
      setheadermenuData({ currentpath: "/financials", headerfootershow: true })
    );
    getCountries().then((meta) => {
      setCountries(meta);
    });
    setSearch("company_country", "");
    setSearch("selectedChildCountry", "");

    let countryfromsearch = getSearch("company_country");
    if (countryfromsearch && countryfromsearch !== "")
      parseCountry = countryfromsearch;
    if (parseCountry != "") {
      setselectedCountry(parseCountry);
      startOvercompanies(parseCountry);
      dispatch(setcompanybycountryval(""));
    } else {
      startOvercompanies(selectedCountry);
    }

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (!isFetching) return false;
    fetchNews();
  }, [isFetching]);

  function handleScroll() {
    if (hasmorenews) {
      if (
        window.innerHeight +
          (parseInt(document.documentElement.scrollTop) + 2000) <
          document.documentElement.offsetHeight ||
        isFetching
      )
        return;
      setIsFetching(true);
    }
  }

  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    // if (News.length > 0) {
    //   if (
    //     (selectedCountry == "UAE" ||
    //       selectedCountry == "KSA" ||
    //       selectedCountry == "Kuwait" ||
    //       selectedCountry == "Bahrain" ||
    //       selectedCountry == "Qatar" ||
    //       selectedCountry == "Oman") &&
    //     selectedChildCountry != ""
    //   ) {
    //     // console.log(selectedChildCountry);
    //     // setRows(cacheData?.[selectedChildCountry] ?? []);
    //     setRows(getRowData(News, selectedChildCountry));
    //     setSearchText("");
    //     setIsLoading(false);
    //   } else {
    //     // console.log(selectedChildCountry);
    //     // startOvercompanies(selectedCountry);
    //     setRows(getRowData(News, selectedCountry));
    //     setSearchText("");
    //     setIsLoading(false);
    //   }
    // }
    if (selectedChildCountry !== "") {
      getCompaniesByCountry(selectedCountry, 0, 0, selectedChildCountry).then(
        (meta) => {
          setNews(meta.data);
          setTotalCompanies(meta.metadata.total_companies);
          setcurrentNewsPosition(currentNewsPosition + 1);
        }
      );
    }
  }, [selectedChildCountry]);

  // console.log(rows);

  return (
    <div style={{ minHeight: "90vh" }} className="mb-3">
      <ScrollToTop smooth />
      <div className="container-fluid nopaddingcontainer d-flex justify-content-center">
        <div className="container card section_divider">
          <div className="row section_divider">
            <div className="col-md-6">
              <Button
                style={{
                  borderTopLeftRadius: "5px",
                  borderBottomLeftRadius: "5px",
                  padding: "0px",
                  transform: "translateX(-10px)",
                }}
                onClick={handleBack}
              >
                {" "}
                <span className="back_btn_txt2">
                  <img
                    alt="Back"
                    style={{ transform: "rotateZ(90deg)" }}
                    srcSet="/assets/icons/Dropdown.svg"
                  />{" "}
                  Back
                </span>
              </Button>
            </div>
            <div className="col-md-6">
              <div className="row search_input_box_NewsPage">
                <div className="col-1" style={{ "padding-right": "0px" }}>
                  <img
                    className="search_icon_r"
                    alt=""
                    srcSet="/assets/icons/search_icon_light.svg"
                  />
                </div>
                <div className="col-11">
                  <input
                    onChange={(e) => handleSearch(e.target.value)}
                    // onChange={(e) => setSearchText(e.target.value)}
                    value={SearchText}
                    className="search_input_NewsPage "
                    placeholder="Search"
                  />
                  {SearchText !== "" ? (
                    <div
                      onClick={(e) => handleSearch("")}
                      className="searchclose"
                    >
                      <button className="btn_trans">
                        <svg
                          width="20"
                          style={{ fill: "#555" }}
                          height="20"
                          focusable="false"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.58 12 5 17.58 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                        </svg>
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row section_divider">
            <div className="col-md-12">
              <div className="flags_div d-flex">
                <Chip
                  onClick={() => changeCountry("")}
                  avatar={<Avatar alt="A" src="/assets/icons/globe.png" />}
                  label="All"
                  variant={selectedCountry == "" ? "" : "outlined"}
                />
                {Countries.length == 0
                  ? ""
                  : Countries.map(function (value, index, array) {
                      return (
                        <Chip
                          className="ml-1 countrychips_homepage"
                          key={index}
                          onClick={() => {
                            if (selectedCountry != value.country) {
                              setIsLoading(true);
                              // setselectedCountry(value.country);
                            }
                            changeCountry(value.country);
                          }}
                          avatar={
                            <Avatar
                              alt={value.country[0]}
                              src={getFlag(value.country)}
                            />
                          }
                          label={value.country}
                          variant={
                            selectedCountry == value.country ? "" : "outlined"
                          }
                        />
                      );
                    })}
              </div>
            </div>
          </div>
          {selectedCountry == "KSA" && (
            <Stack flexDirection="row" gap="4px" sx={{ ml: "75px" }}>
              <Chip
                id="tasi"
                sx={{
                  width: "45px",
                  span: {
                    p: 0,
                  },
                }}
                label="TASI"
                onClick={() => {
                  if (selectedChildCountry != "TASI") setIsLoading(true);
                  setSearch("selectedChildCountry", "TASI");
                  setselectedChildCountry("TASI");
                  document.getElementById("tasi").classList.add("ksa-markets");
                  document
                    .getElementById("nomu")
                    .classList.remove("ksa-markets");
                }}
                // variant={selectedChildCountry == "TASI" ? "" : "outlined"}
              />
              <Chip
                id="nomu"
                sx={{
                  width: "55px",
                  span: {
                    p: 0,
                  },
                }}
                label="NOMU"
                onClick={() => {
                  if (selectedChildCountry != "NOMU") setIsLoading(true);
                  setSearch("selectedChildCountry", "NOMU");
                  setselectedChildCountry("NOMU");
                  document.getElementById("nomu").classList.add("ksa-markets");
                  document
                    .getElementById("tasi")
                    .classList.remove("ksa-markets");
                }}
                // variant={selectedChildCountry == "NOMU" ? "" : "outlined"}
              />
            </Stack>
          )}
          {selectedCountry == "UAE" && (
            <Stack flexDirection="row" gap="4px" sx={{ ml: "178px" }}>
              <Chip
                id="dfm"
                sx={{
                  width: "45px",
                  span: {
                    p: 0,
                  },
                }}
                label="DFM"
                onClick={() => {
                  if (selectedChildCountry != "DFM") setIsLoading(true);
                  setSearch("selectedChildCountry", "DFM");
                  setselectedChildCountry("DFM");
                  document.getElementById("dfm").classList.add("uae-markets");
                  document
                    .getElementById("adx")
                    .classList.remove("uae-markets");
                }}
                // variant={selectedChildCountry == "DFM" ? "" : "outlined"}
              />
              <Chip
                id="adx"
                sx={{
                  width: "45px",
                  span: {
                    p: 0,
                  },
                }}
                label="ADX"
                onClick={() => {
                  if (selectedChildCountry != "ADX") setIsLoading(true);
                  setSearch("selectedChildCountry", "ADX");
                  setselectedChildCountry("ADX");
                  document.getElementById("adx").classList.add("uae-markets");
                  document
                    .getElementById("dfm")
                    .classList.remove("uae-markets");
                }}
                // variant={selectedChildCountry == "ADX" ? "" : "outlined"}
              />
            </Stack>
          )}
          {selectedCountry == "Kuwait" && (
            <Stack flexDirection="row" gap="4px" sx={{ ml: "212px" }}>
              <Chip
                id="main-mkt"
                sx={{
                  width: "110px",
                  span: {
                    p: 0,
                  },
                }}
                label="MAIN MARKET"
                onClick={() => {
                  if (selectedChildCountry != "MAIN MARKET") setIsLoading(true);
                  setSearch("selectedChildCountry", "MAIN MARKET");
                  setselectedChildCountry("MAIN MARKET");
                  document
                    .getElementById("main-mkt")
                    .classList.add("kuwait-markets");
                  document
                    .getElementById("premier-mkt")
                    .classList.remove("kuwait-markets");
                }}
                // variant={
                //   selectedChildCountry == "MAIN MARKET" ? "" : "outlined"
                // }
              />
              <Chip
                id="premier-mkt"
                sx={{
                  width: "130px",
                  span: {
                    p: 0,
                  },
                }}
                label="PREMIER MARKET"
                onClick={() => {
                  if (selectedChildCountry != "PREMIER MARKET")
                    setIsLoading(true);
                  setSearch("selectedChildCountry", "PREMIER MARKET");
                  setselectedChildCountry("PREMIER MARKET");
                  document
                    .getElementById("premier-mkt")
                    .classList.add("kuwait-markets");
                  document
                    .getElementById("main-mkt")
                    .classList.remove("kuwait-markets");
                }}
                // variant={
                //   selectedChildCountry == "PREMIER MARKET" ? "" : "outlined"
                // }
              />
            </Stack>
          )}
          {selectedCountry == "Bahrain" && (
            <Stack flexDirection="row" gap="4px" sx={{ ml: "350px" }}>
              <Chip
                id="bahrain"
                sx={{
                  width: "130px",
                  span: {
                    p: 0,
                  },
                }}
                label="BAHRAIN BOURSE"
                className="bahrain-markets"
                // onClick={() => {
                //   if (selectedChildCountry != "BAHRAIN BOURSE")
                //     setIsLoading(true);
                //   setSearch("selectedChildCountry", "BAHRAIN BOURSE");
                //   setselectedChildCountry("BAHRAIN BOURSE");
                //   document
                //     .getElementById("bahrain")
                //     .classList.add("bahrain-markets");
                // }}
                // variant={
                //   selectedChildCountry == "BAHRAIN BOURSE" ? "" : "outlined"
                // }
              />
            </Stack>
          )}
          {selectedCountry == "Qatar" && (
            <Stack flexDirection="row" gap="4px" sx={{ ml: "426px" }}>
              <Chip
                id="qatar"
                sx={{
                  width: "180px",
                  span: {
                    p: 0,
                  },
                }}
                label="QATAR STOCK EXCHANGE"
                className="qatar-markets"
                // onClick={() => {
                //   if (selectedChildCountry != "QATAR STOCK EXCHANGE")
                //     setIsLoading(true);
                //   setSearch("selectedChildCountry", "QATAR STOCK EXCHANGE");
                //   setselectedChildCountry("QATAR STOCK EXCHANGE");
                //   document
                //     .getElementById("qatar")
                //     .classList.add("qatar-markets");
                // }}
                // variant={
                //   selectedChildCountry == "QATAR STOCK EXCHANGE"
                //     ? ""
                //     : "outlined"
                // }
              />
            </Stack>
          )}
          {selectedCountry == "Oman" && (
            <Stack flexDirection="row" gap="4px" sx={{ ml: "330px" }}>
              <Chip
                id="under-monitoring"
                sx={{
                  width: "210px",
                  span: {
                    p: 0,
                  },
                }}
                label="UNDER_MONITORING MARKET"
                onClick={() => {
                  if (selectedChildCountry != "UNDER_MONITORING MARKET")
                    setIsLoading(true);
                  setSearch("selectedChildCountry", "UNDER_MONITORING MARKET");
                  setselectedChildCountry("UNDER_MONITORING MARKET");
                  document
                    .getElementById("under-monitoring")
                    .classList.add("oman-markets");
                  document
                    .getElementById("regular-mkt")
                    .classList.remove("oman-markets");
                  document
                    .getElementById("parallel-mkt")
                    .classList.remove("oman-markets");
                }}
                // variant={
                //   selectedChildCountry == "UNDER_MONITORING MARKET"
                //     ? ""
                //     : "outlined"
                // }
              />
              <Chip
                id="regular-mkt"
                sx={{
                  width: "130px",
                  span: {
                    p: 0,
                  },
                }}
                label="REGULAR MARKET"
                onClick={() => {
                  if (selectedChildCountry != "REGULAR MARKET")
                    setIsLoading(true);
                  setSearch("selectedChildCountry", "REGULAR MARKET");
                  setselectedChildCountry("REGULAR MARKET");
                  document
                    .getElementById("regular-mkt")
                    .classList.add("oman-markets");
                  document
                    .getElementById("under-monitoring")
                    .classList.remove("oman-markets");
                  document
                    .getElementById("parallel-mkt")
                    .classList.remove("oman-markets");
                }}
                // variant={
                //   selectedChildCountry == "REGULAR MARKET" ? "" : "outlined"
                // }
              />
              <Chip
                id="parallel-mkt"
                sx={{
                  width: "130px",
                  span: {
                    p: 0,
                  },
                }}
                label="PARALLEL MARKET"
                onClick={() => {
                  if (selectedChildCountry != "PARALLEL MARKET")
                    setIsLoading(true);
                  setSearch("selectedChildCountry", "PARALLEL MARKET");
                  setselectedChildCountry("PARALLEL MARKET");
                  document
                    .getElementById("parallel-mkt")
                    .classList.add("oman-markets");
                  document
                    .getElementById("under-monitoring")
                    .classList.remove("oman-markets");
                  document
                    .getElementById("regular-mkt")
                    .classList.remove("oman-markets");
                }}
                // variant={
                //   selectedChildCountry == "PARALLEL MARKET"
                //     ? ""
                //     : "outlined"
                // }
              />
            </Stack>
          )}
          <div className="row">
            <div className="col-md-12  news_cards_div">
              <label className="labelasheading">
                Companies{" "}
                <span className="companylistingtxt">
                  (A total of {TotalCompanies} listed companies{" "}
                  {selectedChildCountry != ""
                    ? " in " + selectedChildCountry
                    : selectedCountry}{" "}
                  as of {format(new Date(), "MMM d, Y")})
                </span>
              </label>
            </div>
            <div className="col-md-12">
              <Grid className="company_list_card" container spacing={2}>
                <>
                  {News.length == 0
                    ? ""
                    : News.map(function (value, index, array) {
                        var isSearched = SearchText !== "";
                        // console.log(isSearched);
                        return (
                          <CompanyListCard
                            isSearched={isSearched}
                            value={value}
                            key={index}
                            CompanyImage={value.image}
                            CompanyName={value.Company_Name}
                            CompanyId={value.id}
                          />
                        );
                      })}
                </>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newspage;
