//Google Tag Manager
const GTM_ID = 'GTM-5XD8S65'; 

//Google Analytics : Property ID
const GA_PROPERTYID = "299684811";

//Google Analytics : Tracking ID
const GA_TRACKINGID = 'G-37YDP0PFP9'; 

export {GTM_ID, GA_TRACKINGID, GA_PROPERTYID};
